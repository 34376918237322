import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import LoaderGif from "./images/loading.gif";
import "./styles/page.scss";

function Page() {
  const [html, setHtml] = useState(null);
  let { slug } = useParams();

  useEffect(() => {
    const getData = async () => {
      const resp = await axios.get(
        `${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/pages?filter[name]=${slug}`
      );
      const data = resp.data[0];

      //Set html
      if (data?.content?.rendered) {
        setHtml(data.content.rendered);
      }

      //Set title
      if (data?.title?.rendered) {
        const title = document.getElementsByTagName("TITLE")[0];
        title.innerHTML = data.title.rendered;
      }
    };

    getData();
  }, [slug]);
  return (
    <div className="pageContainer">
      {html ? (
        <div dangerouslySetInnerHTML={{ __html: html }} />
      ) : (
        <img src={LoaderGif} alt="loading" className="loader" />
      )}
    </div>
  );
}

export default Page;
